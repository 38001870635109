import { useState, useEffect, useCallback } from 'react';
import { Input } from '../../../common/Forms/Input/Input';
import { notify } from '../../../../assets/helpers/toast';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as schemas from '../../../../assets/schemas/schemas';
import { ActionButton, AltButton } from '../../../common/Buttons/Buttons';
import {
  paymentTypeOptions,
  paymentMethodsLimit,
  paymentMethods,
  paymentType as paymentTypeOption, pagoMovilList, bankTransferList
} from '../../../../assets/helpers/options';
import { productService } from '../../../../services/productService';
import { paymentsService } from '../../../../services/paymentsService';
import { stripeService } from '../../../../services/stripeService';
import safePayment from '../../../../images/safePayment.svg';
import { salesRoles } from '../../../../assets/helpers/roles';
import {
  StripeModal,
  NewPaymentModal,
  CasheaModal
} from '../LandingComponents/LandingModals';
import { IGTFPaymentModal } from '../../../common/Modals/Modals';
import { useDropzone } from 'react-dropzone';
import './LandingForms.scss';
import { membershipService } from '../../../../services/membershipService';
import { LoadSpinner } from '../../../common/LoadSpinner/LoadSpinner';
import { Method1Component } from './Method1Component';
import { Method2Component } from './Method2Component';
import { SecondQuotaComponent } from './SecondQuotaComponent';
import { secondQuotaTime } from '../../../../assets/helpers/variables';
import { NaNtoNumber, filterParams, filterById } from '../../../../assets/helpers/funciones';
import authService from '../../../../services/authService';

export const PlanPurchaseForm = (props) => {
  const {
    setcurentStep,
    curentStep,
    dollarRate,
    membership,
    selectedMembership,
  } = props;

  const t = useTranslation();

  const [paymemtMethodOptions, setPaymemtMethodOptions] = useState([]);
  const [paymentImage1, setPaymentImage1] = useState('');
  const [showIGTFPaymentModal, setShowIGTFPaymentModal] = useState({
    isOpen: false,
    method: '',
    amount1: 0,
  });
  const [stripeModal, setStripeModal] = useState({
    isOpen: false,
    methods: [],
  });
  const [clientSecret, setClientSecret] = useState('');
  const [paymentImage2, setPaymentImage2] = useState('');
  const [selectedMethod, setSelectedMethod] = useState('');
  const [paymentTypeVar, setPaymentTypeVar] = useState([]);

  const [payment, setPayment] = useState({
    amount: 1,
    amountBsS: 1,
    methods: [],
    reference: '',
  });

  const [casheaPayment, setCasheaPayment] = useState({
    amount: 1,
    amountBsS: 1,
    methods: [],
    reference: '',
    initial: "",
    transactionId: "",
  });

  const [isLoading, setIsloading] = useState(false);
  const onDrop1 = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = () => {
        // Do whatever you want with the file contents
      };
      reader.readAsArrayBuffer(file);
      if (
        !['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'].includes(
          file.type
        )
      ) {
        notify('Agregar un formato valido de imagen', 'error');
        return null;
      }
      let maxWeight = 5;
      if (file.size * 0.000001 > maxWeight) {
        notify(`La imagen no debe superar los ${maxWeight} MB`, 'error');
      } else {
        setPaymentImage1(acceptedFiles[0]);
      }
    });
  }, []);
  const onDrop2 = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = () => {
        // Do whatever you want with the file contents
      };
      reader.readAsArrayBuffer(file);
      if (
        !['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'].includes(
          file.type
        )
      ) {
        notify('Agregar un formato valido de imagen', 'error');
        return null;
      }
      let maxWeight = 5;
      if (file.size * 0.000001 > maxWeight) {
        notify(`La imagen no debe superar los ${maxWeight} MB`, 'error');
      } else {
        setPaymentImage2(acceptedFiles[0]);
      }
    });
  }, []);

  const removeImage = (paymentNumber) => {
    if (paymentNumber === 1) {
      setPaymentImage1('');
    }
    if (paymentNumber === 2) {
      setPaymentImage2('');
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    watch,
    control,
    setError
  } = useForm({
    resolver: yupResolver(schemas.planPurchase(t)),
  });

  const validatePaymentOptions = () => {
    let user = localStorage.getItem('user');
    user = JSON.parse(user);

    let array = [...paymentTypeOptions];
    if (
      ![
        salesRoles.SALES_DEVELOPER,
        salesRoles.SALES_ADMIN,
        salesRoles.SALES_SUPERVISOR,
        salesRoles.SALES_HUNTER,
        salesRoles.SALES_FARMER,
        salesRoles.SALES_COUNTER,
        salesRoles.SALES_MARKETING,
        salesRoles.SALES_ADMIN_AUX,
      ].includes(user?.roles[0]?.code)
    ) {
      array = array.filter(
        (a) =>
          ![
            paymentTypeOption?.PENDING,
            paymentTypeOption?.COURTESY,
            paymentTypeOption?.FINANCIADO,
          ].includes(a?._id)
      );
    }

    if (
      [
        salesRoles.SALES_DEVELOPER,
        salesRoles.SALES_ADMIN,
        salesRoles.SALES_HUNTER,
        salesRoles.SALES_FARMER,
        salesRoles.SALES_COUNTER,
      ].includes(user?.roles[0]?.code)
    ) {
      array?.push({
        _id: paymentTypeOption?.CASHEA,
        name: 'Cashea',
      })
    }

    setPaymentTypeVar(array);
  };

  // Validate if all payments were made via TDC
  const paymentsWithTDC = (stripeId) => {
    // Si esta loggeado no pasas por el proceso regular de pago de TCD
    if (authService?.getUser()) {
      return false;
    }

    let paymenTDCounter = 0;
    let paymentMethodsQuantity = parseInt(getValues('paymentMethodsQuantity'));
    for (let i = 0; i < paymentMethodsQuantity; i++) {
      if (stripeId?._id === getValues(`method${i + 1}`)) {
        paymenTDCounter = paymenTDCounter + 1;
      }
    }
    if (paymenTDCounter === paymentMethodsQuantity) {
      // Save data to local storage and jump to next step
      updateMembershipStatus(membership?._id);
      setcurentStep(curentStep + 1);
      return true;
    } else {
      return false;
    }
  };

  const storeData = (params) => {
    for (let i = 0; i < params?.payments?.length; i++) {
      params.payments[i].methodName = paymemtMethodOptions?.filter(
        (p) => p?._id === params?.payments[i]?.method
      )[0]?.name;
      params.payments[i].currency = paymemtMethodOptions?.filter(
        (p) => p?._id === params?.payments[i]?.method
      )[0]?.currency;
    }

    sessionStorage.setItem('payment', JSON.stringify(params));
  };

  const handleGenericPaymentMethods = (index) => {
    let selectedMethod = filterById(paymemtMethodOptions, watch("paymentOption"))
    setValue(`method${index}`, selectedMethod?._id)
  }

  let loadPaymentForms = (params) => {
    let [stripeId] = paymemtMethodOptions?.filter(
      (p) => p?.name === paymentMethods?.STRIPE
    );

    let payments = [];
    let allPayments = [];
    // lOOP through every payment method
    for (let i = 0; i < parseInt(getValues('paymentMethodsQuantity')); i++) {
      let data = {
        method: params[`method` + (i + 1).toString()],
        reference: params[`reference` + (i + 1).toString()],
        amountIGTF: parseFloat(params[`amount` + (i + 1).toString() + 'IGTF']),
        amount: parseFloat(params[`amount` + (i + 1).toString()]),
        amountBsS: parseFloat(params[`amountBsS` + (i + 1).toString()]),
        bank: params[`bank` + (i + 1).toString()],
        phone: params[`phone` + (i + 1).toString()],
        date: params[`date` + (i + 1).toString()],
      }
      allPayments.push(filterParams(data));

      // If payment is made trough stripe then the payment is not added to the array
      if (
        stripeId?._id !== params[`method` + (i + 1).toString()] ||
        authService?.getUser()
      ) {
        let data = {};
        data.method = params[`method` + (i + 1).toString()];
        data.amountBsS = params[`amountBsS` + (i + 1).toString()];
        data.reference = params[`reference` + (i + 1).toString()];
        data.amountIGTF = parseFloat(
          params[`amount` + (i + 1).toString() + 'IGTF']
        );
        data.bank = params[`bank` + (i + 1).toString()];
        data.phone = params[`phone` + (i + 1).toString()];
        data.date = params[`date` + (i + 1).toString()];

        // Amount with or without IGTF
        let amount1IGTF = NaNtoNumber(parseFloat(watch('amount1IGTF')));
        let amount1_2IGTF = NaNtoNumber(parseFloat(watch('amount1_2IGTF')));
        let amount2IGTF = NaNtoNumber(parseFloat(watch('amount2IGTF')));

        data.amount = parseFloat(params[`amount` + (i + 1).toString()]);

        // Add IGTF 1 and raise flag
        if (i === 0 && amount1IGTF > 0) {
          data.igtf = true;
          data.amount =
            amount1IGTF + parseFloat(params[`amount` + (i + 1).toString()]);
        }

        // Add IGTF 1 in 2 or 2 and raise flag
        if (i !== 0 && (amount1_2IGTF > 0 || amount2IGTF > 0)) {
          data.igtf = true;
          data.amount =
            amount2IGTF + parseFloat(params[`amount` + (i + 1).toString()]);

          if (watch('mix')) {
            data.amount = data.amount + amount1_2IGTF;
          }
        }

        data.amount = data.amount?.toFixed(2);

        payments?.push({
          ...data,
        });
      }
    }

    // "REmove unwanted params from JSON"
    let words = [
      'method',
      'reference',
      'amount',
      'amountIGTF',
      'amountBsS',
      'paymentMethod',
      'bank',
      'phone',
      'date',
    ];
    for (const key in params) {
      for (var i = 0, ln = words?.length; i < ln; i++) {
        if (key.indexOf(words[i]) !== -1) {
          delete params[key];
        }
      }
    }

    params.payments = payments;
    params.allPayments = allPayments;
    storeData(params);
    const tdc = paymentsWithTDC(stripeId);

    if (tdc) {
      return null;
    } else {
      return params;
    }
  };

  const getNoImageMethods = () => {
    let array = paymemtMethodOptions?.filter((p) =>
      [paymentMethods?.PUNTO_DE_VENTA, paymentMethods?.TDC, paymentMethods?.CASHEA]?.includes(p?.name)
    );

    let filtered = [];

    for (let i = 0; i < array.length; i++) {
      filtered?.push(array[i]?._id);
    }

    return filtered;
  };

  const updateMembershipStatus = (membershipId) => {
    setIsloading(true);
    membershipService
      .updateMembershipStatus(membershipId)
      .then((response) => {
        if ([200, 201].includes(response.status)) {
          setcurentStep(curentStep + 1);
          setIsloading(false);
        } else {
          setIsloading(false);
          notify(`${response.message}`, 'error');
        }
      })
      .catch((error) => {
        setIsloading(false);
      });
  };

  const specialPayment = () => {
    const formData = new FormData();

    formData.append('paymentType', watch('paymentType'));
    formData.append('membership', membership?._id);

    if (!isLoading) {
      setIsloading(true);
      paymentsService
        .createMembershipPayment(formData)
        .then((response) => {
          if ([200, 201].includes(response.status)) {
            updateMembershipStatus(membership?._id);
          } else {
            setIsloading(false);
            notify(`${response.message}`, 'error');
          }
        })
        .catch((error) => {
          setIsloading(false);
        });
    }
  };


  const onSubmit = handleSubmit(async (data) => {
    let dataToPost = { ...data };
    delete dataToPost?.finalPrice;
    delete dataToPost?.finalPriceInBsS;
    delete dataToPost?.paymentOption;
    delete dataToPost?.selectedMembership;

    dataToPost.membership = membership?._id;
    dataToPost = loadPaymentForms(dataToPost);

    if (!dataToPost) {
      return null;
    }

    let fileToPost = appendFile(dataToPost);

    if (!isLoading && fileToPost) {
      setIsloading(true);
      paymentsService
        .createMembershipPayment(fileToPost)
        .then((response) => {
          if ([200, 201].includes(response.status)) {
            updateMembershipStatus(membership?._id);
            // setIsloading(false);
          } else {
            setIsloading(false);
            notify(`${response.message}`, 'error');
          }
        })
        .catch((error) => {
          setIsloading(false);
          notify(`ERROR al realizar el pago`, 'error');
        });
    }
  });

  const appendFile = (data) => {
    const formData = new FormData();
    formData.append('membership', data?.membership);
    formData.append('paymentType', data?.paymentType);

    for (var i = 0; i < data?.payments?.length; i++) {
      if (data?.payments[i]?.amountIGTF) {
        formData.append(`payments[${i}][amount]`, data?.payments[i]?.amount);
      } else {
        formData.append(`payments[${i}][amount]`, data?.payments[i]?.amount);
      }
      if (data?.payments[i]?.igtf) {
        formData.append(`payments[${i}][igtf]`, data?.payments[i]?.igtf);
      }
      if (data?.payments[i]?.bank) {
        formData.append(`payments[${i}][phone]`, data?.payments[i]?.phone);
        formData.append(`payments[${i}][bank]`, data?.payments[i]?.bank);
        formData.append(`payments[${i}][date]`, data?.payments[i]?.date);
      }

      formData.append(
        `payments[${i}][amountBsS]`,
        data?.payments[i]?.amount * dollarRate
      );
      formData.append(`payments[${i}][method]`, data?.payments[i]?.method);
      formData.append(
        `payments[${i}][reference]`,
        data?.payments[i]?.reference
      );
    }

    // Load as many files as payment methods we have
    let noImageMethods = getNoImageMethods();

    if (
      parseFloat(watch('paymentMethodsQuantity')) > 0 &&
      !paymentImage1 &&
      !noImageMethods?.includes(data?.payments[0]?.method)
    ) {
      notify('Ingrese comprobantes de pago 1', 'error');
      return null;
    }

    if (
      parseFloat(watch('paymentMethodsQuantity')) > 1 &&
      !paymentImage2 &&
      // !paymentImage1 &&
      !noImageMethods?.includes(data?.payments[1]?.method)
    ) {
      notify('Ingrese comprobantes de pago 2 ', 'error');
      return null;
    }

    // VAlidate payment amount

    // Load Payment methods images
    if (
      parseFloat(watch('paymentMethodsQuantity')) > 0 &&
      paymentImage1?.name
    ) {
      formData.append(`paymentEvidence`, paymentImage1);
    }
    if (
      parseFloat(watch('paymentMethodsQuantity')) > 1 &&
      paymentImage2?.name
    ) {
      formData.append(`paymentEvidence`, paymentImage2);
    }

    return formData;
  };

  const { getRootProps: getRootProps1, getInputProps: getInputProps1 } =
    useDropzone({ onDrop: onDrop1 });
  const { getRootProps: getRootProps2, getInputProps: getInputProps2 } =
    useDropzone({
      onDrop: onDrop2,
    });
  const getPaymentMethods = () => {
    productService
      .getPaymentMethods('sales')
      .then((response) => {
        if (response.status === 200) {
          let data = response?.data?.filter((d) => d?.name !== 'CASHEA');
          setPaymemtMethodOptions(data);
        } else {
          notify(`${response.message}`, 'error');
        }
      })
      .catch(() => { });
  };


  const validatePagoMovil = (params, index) => {
    let isValid = true
    if (watch(`phone${index}`)?.length !== 10) {
      setError(`phone${index}`, { type: 'custom', message: 'Ingrese un numero de telefono valido: Ejemplo: 4143579865' });
      return false
    } else {
      setError(`phone${index}`, { type: 'custom', message: '' });
    }

    for (let i = 0; i < params?.length; i++) {
      if (!watch(`${params[i]}${index}`)) {
        setError(`${params[i]}${index}`, { type: 'custom', message: 'Obligatorio' });
        isValid = false
      }
    }
    return isValid
  }

  const acceptPayment = () => {
    // IF the payment is made in CASH then set the ref message
    for (let i = 0; i < payment?.methods?.length; i++) {
      if (
        [paymentMethods.CASH_BS, paymentMethods.CASH_USD].includes(
          payment?.methods[i]?.name
        )
      ) {
        setValue(`reference${payment?.paymentNumber}`, 'PAGO EFECTIVO');
      }
    }


    const params = ["phone", "bank", "date"]

    // Valida los Pago movil de bancamigasolamente
    if ([paymentMethods?.PAGO_MÓVIL_BANCAMIGA]?.includes(
      payment?.methods[0]?.name
    )) {

      const isPMValid = validatePagoMovil(params, payment?.paymentNumber)
      if (!isPMValid) {
        return null
      }
    }
    // Validate If first Image is Up
    if (
      payment?.paymentNumber === 1 &&
      ![paymentMethods.PUNTO_DE_VENTA, paymentMethods.TDC]?.includes(
        payment?.methods[0]?.name
      ) &&
      (!watch('reference1') || !paymentImage1)
    ) {
      notify('Subir imagen y datos de referencia de pago', 'error');
      return null;
    }
    // VALIDATE iF SECOND IMAGE IS UP
    if (
      payment?.paymentNumber === 2 &&
      ![paymentMethods.PUNTO_DE_VENTA, paymentMethods.TDC]?.includes(
        payment?.methods[0]?.name
      ) &&
      (!watch('reference2') || !paymentImage2)
    ) {
      notify('Subir imagen y datos de referencia de pago', 'error');
      return null;
    }

    // IF method is cash dont ask for reference and pay

    setSelectedMethod('');

    setPayment((prevState) => {
      return {
        ...prevState,
        isOpen: false,
        methods: [],
        paymentNumber: '',
        reference: '',
        selectedMembership: watch('selectedMembership')
          ? selectedMembership
          : '',
      };
    });
  };


  const acceptCasheaPayment = () => {
    setValue("amount1", watch("financialAmount1"))
    setValue("amountBsS1", watch("financialAmount2"))
    setValue("reference1", casheaPayment?.transactionId)

    setCasheaPayment((prevState) => {
      return {
        ...prevState,
        isOpen: false,
      };
    });
  };


  const successfulStripe = (paymentNumber) => {
    setValue(`reference${paymentNumber}`, 'PAGO REALIZADO');
    setValue(
      `method${paymentNumber}`,
      paymemtMethodOptions.filter((p) => p.name === paymentMethods?.STRIPE)[0]
        ?._id
    );

    if (paymentNumber === 1) {
      setPaymentImage1('A');
    }
    if (paymentNumber === 2) {
      setPaymentImage2('A');
    }

    setStripeModal((prevState) => {
      return {
        ...prevState,
        isOpen: !stripeModal?.isOpen,
        methods: [],
        amount: 0,
        paymentNumber: '',
        selectedMembership: '',
      };
    });
  };

  const handleStipeModal = (method, amount, paymentNumber) => {
    let selectedPaymentMethods = paymemtMethodOptions.filter(
      (p) => p.name === paymentMethods?.STRIPE
    );

    if (!method) {
      setValue(`reference${paymentNumber}`, '');
      setValue(`method${paymentNumber}`, '');
      setValue(`paymentOption`, '');
      removeImage(paymentNumber);
      setValue(`amount${paymentNumber}IGTF`, 0);
    }

    setStripeModal((prevState) => {
      return {
        ...prevState,
        isOpen: !stripeModal?.isOpen,
        methods: selectedPaymentMethods,
        paymentNumber: paymentNumber ? paymentNumber : '',
        amount: amount ? amount : 0,
        selectedMembership: watch('selectedMembership')
          ? selectedMembership
          : '',
      };
    });
  };

  const getUSDFromBs = (e) => {
    const { name, value } = e?.target;
    let paymentNumber = name.replace('amountBsS', '');
    setValue(`amount${paymentNumber}`, (value / dollarRate).toFixed(2));
  };

  const setSecondPayment = (e) => {
    const { value } = e?.target;

    if (parseFloat(value) > membership?.priceInfo?.finalPrice) {
      notify('Su pago exede el costo de la membresia', 'error');

      setValue('amount1', membership?.priceInfo?.finalPrice);
      setValue('amount2', membership?.priceInfo?.finalPrice - watch('amount1'));
      setValue(
        `amountBsS2`,
        (parseFloat(watch('amount2')) * dollarRate).toFixed(2)
      );
      return null;
    }

    if (watch('paymentType') === paymentTypeOption?.FINANCIADO) {
      recalculateSecondQuota();
    }

    // Set second payment amount
    if (
      parseFloat(watch('paymentMethodsQuantity')) === 2 &&
      watch('paymentType') !== paymentTypeOption?.FINANCIADO
    ) {
      setValue('amount2', membership?.priceInfo?.finalPrice - watch('amount1'));
      setValue(
        `amountBsS2`,
        (parseFloat(watch('amount2')) * dollarRate).toFixed(2)
      );
    }

    getBsfromUSD(e);
  };

  const recalculateSecondQuota = () => {
    let amount1 = NaNtoNumber(parseFloat(watch('amount1')))
    let amount2 = NaNtoNumber(parseFloat(watch('amount2')))

    if ((amount1 + amount2) === membership?.priceInfo?.finalPrice && watch('paymentType') === paymentTypeOption?.FINANCIADO) {
      setValue('amount1', '');
      setValue('amountBsS1', '');
      setValue('amount2', '');
      setValue(`amountBsS2`, '');

      notify('La suma de las cuotas debe ser diferente al total de la membresia financiada', 'error');
    }


    let financialAmount2 =
      membership?.priceInfo?.finalPrice -
      amount1 -
      amount2;

    if (financialAmount2 > 0) {
      setValue('financialAmount2', financialAmount2);
      setValue(
        'financialAmountBsS2',
        (parseFloat(watch('financialAmount2')) * dollarRate).toFixed(2)
      );
    }

    if (financialAmount2 < 0) {
      notify('El monto final debe ser mayor a 0', 'error');
      setValue('amount2', '');
      setValue(`amountBsS2`, '');
    }
  };

  const getBsfromUSD = (e) => {
    const { name, value } = e?.target;
    let paymentNumber = name.replace('amount', '');
    setValue(`amountBsS${paymentNumber}`, (value * dollarRate).toFixed(2));
    recalculateSecondQuota();
  };

  const openNewStripeModal = (amount, paymentNumber, igtf) => {
    let data = {
      amount: amount,
      membership: membership?._id,
      paymentType: watch('paymentType'),
      igtf: igtf,
    };

    stripeService
      .paymentIntent(data)
      .then((response) => {
        if (response.status === 200) {
          setClientSecret(response?.data?.clientSecret);
          handleStipeModal('Stripe', amount, paymentNumber);
        } else {
          notify(`${response.message}`, 'error');
        }
      })
      .catch(() => { });
  };

  const preloadValues = () => {
    setValue('finalPrice', membership?.priceInfo?.finalPrice);
    setValue('selectedMembership', selectedMembership?.code);
    setValue(
      'finalPriceInBsS',
      membership?.priceInfo?.finalPriceInBsS?.toFixed(2)
    );
  };

  const selectUniquePayment = () => {
    if (watch('paymentMethodsQuantity') == 1) {
      setValue('mix', false);
      setValue('amount1', membership?.priceInfo?.finalPrice);
      setValue(
        'amountBsS1',
        membership?.priceInfo?.finalPriceInBsS?.toFixed(2)
      );
    } else {
      setValue('mix', true);
      setValue('amount1', '');
      setValue('amountBsS1', '');
    }
  };

  const validatePayment = (paymentImage, paymentNumber) => {
    // IF unique payment cant change value
    if (
      watch('paymentMethodsQuantity') == 1 &&
      watch('paymentType') !== paymentTypeOption?.FINANCIADO
    ) {
      return true;
    }
    // IF paid via POV dont block the input
    if (
      watch(`method${paymentNumber}`) ===
      paymemtMethodOptions?.filter(
        (p) => p?.name === paymentMethods?.PUNTO_DE_VENTA
      )[0]?._id
    ) {
      return true;
    }

    if (
      paymentImage &&
      watch(`reference${paymentNumber}`) &&
      watch(`method${paymentNumber}`)
    ) {
      return true;
    }
  };

  const validateReference = (paymentImage, paymentNumber) => {
    // IF paid via POV show the input
    if (
      watch(`method${paymentNumber}`) ===
      paymemtMethodOptions?.filter(
        (p) => p?.name === paymentMethods?.PUNTO_DE_VENTA
      )[0]?._id
    ) {
      return true;
    }

    if (paymentImage && watch(`reference${paymentNumber}`)) {
      return true;
    }
  };

  const setPaymentType = () => {
    let paymentType = watch('paymentType');
    if ([paymentTypeOption?.DE_CONTADO].includes(paymentType)) {
      setValue('amount1', membership?.priceInfo?.finalPrice);
      setValue(
        'amountBsS1',
        membership?.priceInfo?.finalPriceInBsS?.toFixed(2)
      );
      setValue('paymentMethodsQuantity', '1');
    }

    if (paymentType === paymentTypeOption?.FINANCIADO) {
      setValue('paymentMethodsQuantity', '1');
    }

    if (paymentType === paymentTypeOption?.CASHEA) {
      setValue('paymentMethodsQuantity', '1');
    }

  };

  const validateButtonName = () => {
    let label = 'Siguiente';
    let action = onSubmit;

    if (
      [paymentTypeOption?.COURTESY, paymentTypeOption?.PENDING].includes(
        watch('paymentType')
      )
    ) {
      label = `Registrar pago ${watch('paymentType')}`;
      action = specialPayment;
    }

    return <ActionButton label={label} action={action} />;
  };

  const cancelCasheaPayment = (paymentNumber) => {

    setCasheaPayment((prevState) => {
      return {
        ...prevState,
        isOpen: false,
        methods: [],
        paymentNumber: paymentNumber,
        reference: '',
        transactionId: "",
        initial: ""
      };
    });
  };


  const cancelPayment = (paymentNumber) => {
    setValue(`method${paymentNumber}`, '');
    setValue(`reference${paymentNumber}`, '');
    setValue(`amount${paymentNumber}IGTF`, 0);

    removeImage(paymentNumber);

    setPayment((prevState) => {
      return {
        ...prevState,
        isOpen: false,
        methods: [],
        paymentNumber: paymentNumber,
        reference: '',
      };
    });
  };

  const handleUnmixPayment = (selectedPaymentMethods, paymentNumber) => {
    if (
      [paymentMethods?.TDC]?.includes(selectedPaymentMethods?.name) &&
      !authService?.getUser()
    ) {
      let amount1_2IGTF = NaNtoNumber(parseFloat(watch('amount1_2IGTF')));

      let amount = 0;
      let igtf = false;

      if (amount1_2IGTF > 0) {
        igtf = true;
        amount = amount + amount1_2IGTF;
      }

      openNewStripeModal(parseFloat(amount), 2, igtf);
    } else {
      setPayment((prevState) => {
        return {
          ...prevState,
          isOpen: true,
          amount: parseFloat(watch(`amount${paymentNumber}`)),
          amountBsS: parseFloat(watch(`amountBsS${paymentNumber}`)),
          methods: selectedPaymentMethods?.length > 0 ? [...selectedPaymentMethods] : [selectedPaymentMethods],
          paymentNumber: paymentNumber,
          selectedMembership: watch('selectedMembership')
            ? selectedMembership
            : '',
        };
      });
    }

    if (selectedPaymentMethods?.name === paymentMethods?.PAGO_MÓVIL_BANCAMIGA) {
      let personalData = sessionStorage.getItem('personalData');
      personalData = JSON.parse(personalData);

      setValue(`phone${paymentNumber}`, personalData?.phonePrefix?.slice(1) + personalData?.phoneNumber);;

    }

  };

  const handleMixPayment = (
    selectedPaymentMethods,
    paymentNumber,
    methodIncludesIGTF
  ) => {
    let amount2 = parseFloat(watch('amount2'));
    let amount1_2IGTF = parseFloat(watch('amount1_2IGTF'));
    let amount2IGTF = NaNtoNumber(parseFloat(watch('amount2IGTF')));

    // If the method has IGTF add it to the total amount and show the IGTF2
    if (methodIncludesIGTF) {
      setValue(`amount${paymentNumber}IGTF`, (amount2 * 0.03).toFixed(2));
      amount2 = amount2 * 1.03;
    }

    let igtf = false;
    // If we have IGTF from the previous amount add it to the total amount
    if (amount1_2IGTF > 0) {
      igtf = true;
      amount2 = amount2 + amount1_2IGTF;
    }

    if (
      [paymentMethods?.TDC]?.includes(selectedPaymentMethods?.name) &&
      !authService?.getUser()
    ) {
      if (amount2IGTF > 0) {
        igtf = true;
        amount2 = amount2 + amount2IGTF;
      }
      openNewStripeModal(parseFloat(amount2), 2, igtf);
    } else {
      setPayment((prevState) => {
        return {
          ...prevState,
          isOpen: true,
          amount: amount2,
          amountBsS: dollarRate * amount2,
          methods: [selectedPaymentMethods],
          paymentNumber: paymentNumber,
          selectedMembership: watch('selectedMembership')
            ? selectedMembership
            : '',
        };
      });
    }
  };

  const selectPaymentMethod = (method, paymentNumber) => {
    // Preload payment in the Method section
    let [selectedPaymentMethods] = paymemtMethodOptions.filter(
      (p) => p.name === method
    );
    setValue(`method${paymentNumber}`, selectedPaymentMethods?._id);

    // Si el metodo tiene IGTF abre el modal de pagos de IGTF
    let methodIncludesIGTF = [
      paymentMethods?.STRIPE,
      paymentMethods?.ZELLE,
      paymentMethods?.PAYPAL,
      paymentMethods?.BINANCE,
      paymentMethods?.CASH_USD,
      paymentMethods?.TDC,
    ].includes(method);

    if (method === paymentMethods?.CASHEA) {


      setCasheaPayment((prevState) => {
        return {
          ...prevState,
          isOpen: true,
          amount: parseFloat(watch(`amount${paymentNumber}`)),
          amountBsS: parseFloat(watch(`amountBsS${paymentNumber}`)),
          methods: [selectedPaymentMethods],
          paymentNumber: paymentNumber,
          selectedMembership: watch('selectedMembership')
            ? selectedMembership
            : '',
        };
      });

      return null
    }

    // SI selecciona PM o Transferencia agara los metodos de pago de PMs o Transferencias
    if ([paymentMethods?.PAGO_MÓVIL_GENERICO]?.includes(method)) {
      selectedPaymentMethods = paymemtMethodOptions?.filter(p => pagoMovilList?.includes(p?.name))
    }

    if ([paymentMethods?.TRANSFERENCIA_GENERICO]?.includes(method)) {
      selectedPaymentMethods = paymemtMethodOptions?.filter(p => bankTransferList?.includes(p?.name))
    }

    if (methodIncludesIGTF && paymentNumber === 1) {
      handleIGTFMessageModal(method);
    } else {
      if (watch('mix')) {
        // Abre el modal de pagos con IGTF
        handleMixPayment(
          selectedPaymentMethods,
          paymentNumber,
          methodIncludesIGTF
        );
      } else {
        // Abre el modal de pagos regular sin IGTF
        handleUnmixPayment(selectedPaymentMethods, paymentNumber);
      }
    }
  };

  const handleIGTFMessageModal = (method) => {
    setShowIGTFPaymentModal((prevState) => {
      return {
        ...prevState,
        isOpen: !showIGTFPaymentModal?.isOpen,
        method: method ? method : '',
        amount1: method ? parseFloat(watch('amount1')) : 0,
      };
    });
  };

  const denyIGTFMultipleMethod = () => {
    let methods = paymemtMethodOptions.filter(
      (p) => p.name === showIGTFPaymentModal?.method
    );

    setShowIGTFPaymentModal((prevState) => {
      return {
        ...prevState,
        isOpen: false,
        method: '',
        amount1: 0,
      };
    });

    if (
      [paymentMethods?.TDC]?.includes(methods[0]?.name) &&
      !authService?.getUser()
    ) {
      const igtf = false;
      openNewStripeModal(parseFloat(watch('amount1')), 1, igtf);
    } else {
      setPayment((prevState) => {
        return {
          ...prevState,
          isOpen: true,
          methods: methods,
          paymentNumber: 1,
          amount: parseFloat(watch('amount1')),
          amountBsS: parseFloat(watch('amountBsS1')),
          selectedMembership: watch('selectedMembership')
            ? selectedMembership
            : '',
        };
      });
    }

    setValue('paymentMethodsQuantity', '2');
    setValue('amount1_2IGTF', (watch('amount1') * 0.03).toFixed(2));
  };

  const denyIGTFSingleMethod = () => {
    let methods = paymemtMethodOptions.filter(
      (p) => p.name === showIGTFPaymentModal?.method
    );

    // Close IGTF Modal
    setShowIGTFPaymentModal((prevState) => {
      return {
        ...prevState,
        isOpen: false,
        method: '',
        amount1: 0,
      };
    });

    if (
      [paymentMethods?.TDC]?.includes(methods[0]?.name) &&
      !authService?.getUser()
    ) {
      const igtf = false;
      openNewStripeModal(watch('amount1'), 1, igtf);
    } else {
      setPayment((prevState) => {
        return {
          ...prevState,
          isOpen: true,
          methods: methods,
          paymentNumber: 1,
          amount: parseFloat(watch('amount1')),
          amountBsS: parseFloat(watch('amountBsS1')),
          selectedMembership: watch('selectedMembership')
            ? selectedMembership
            : '',
        };
      });
    }

    setValue('paymentMethodsQuantity', '2');
    setValue('amount2', (watch('amount1') * 0.03).toFixed(2));
    setValue('amountBsS2', (watch('amountBsS1') * 0.03).toFixed(2));
    setValue('amount1_2IGTF', (watch('amount1') * 0.03).toFixed(2));
  };

  // Close IGTF modal and open the payment modal with the amout without the tax
  const denyIGTFMethod = () => {
    if (watch('mix')) {
      denyIGTFMultipleMethod();
    } else {
      denyIGTFSingleMethod();
    }
  };

  const acceptIGTFMethod = () => {
    let [selectedMethod] = paymemtMethodOptions.filter(
      (p) => p?._id === watch('method1')
    );
    setValue('amount1IGTF', (watch('amount1') * 0.03).toFixed(2));

    // Calculate amount
    let amount = parseFloat(watch('amount1') * 1.03);

    if (
      selectedMethod?.name === paymentMethods?.TDC &&
      !authService?.getUser()
    ) {
      const igtf = true;
      openNewStripeModal(amount, 1, igtf);
    } else {
      // Maybe set the amoutnt with IGTF ont the payment section
      // Open Payment modal with IGTF included
      setPayment((prevState) => {
        return {
          ...prevState,
          isOpen: true,
          paymentNumber: 1,
          amount: amount,
          amountBsS: parseFloat(watch('amountBsS1')),
          methods: [selectedMethod],
          selectedMembership: watch('selectedMembership')
            ? selectedMembership
            : '',
        };
      });
    }
    // CLose IGTF payment modal
    setShowIGTFPaymentModal((prevState) => {
      return {
        ...prevState,
        isOpen: false,
        method: '',
        amount1: 0,
      };
    });
  };

  const closeIGTFMessageModal = () => {
    setShowIGTFPaymentModal((prevState) => {
      return {
        ...prevState,
        isOpen: false,
      };
    });
  };

  const handleReferenceChange = (e) => {
    const { value } = e?.target;
    setPayment((prevState) => {
      return {
        ...prevState,
        reference: value,
      };
    });

    setValue(`reference${payment?.paymentNumber}`, value);
  };

  const handleCasheaInitial = (e) => {
    const { value, name } = e?.target;
    setCasheaPayment((prevState) => {
      return {
        ...prevState,
        [name]: value,
        amount: name === "initial" ? watch("finalPrice") * value / 100 : prevState?.amount,
        amountBsS: name === "initial" ? (watch("finalPrice") * value / 100 * dollarRate)?.toFixed(2) : prevState?.amount,
      };
    });

    if (name === "metodo") {
      setValue(`method1`, value);
    }

    if (name === "initial") {
      setValue(`financialAmount1`, (watch("finalPrice") * value / 100));
      setValue(`financialAmountBsS1`, (watch("finalPrice") * value / 100 * dollarRate)?.toFixed(2));
      setValue(`financialAmount2`, ((watch("finalPrice") - watch("finalPrice") * value / 100) / 3)?.toFixed(2));
      setValue(`financialAmountBsS2`, (watch("financialAmount2") * dollarRate / 3)?.toFixed(2));
    }
  };

  useEffect(() => {
    setValue('mix', false);
    validatePaymentOptions();
    getPaymentMethods();
    preloadValues();
  }, []);

  return (
    <>
      <LoadSpinner show={isLoading} />
      <IGTFPaymentModal
        modal={showIGTFPaymentModal}
        handleModal={closeIGTFMessageModal}
        acceptIGTFMethod={acceptIGTFMethod}
        denyIGTFMethod={denyIGTFMethod}
      />
      <StripeModal
        stripeModal={stripeModal}
        handleStipeModal={handleStipeModal}
        clientSecret={clientSecret}
        successfulStripe={successfulStripe}
        watch={watch}
      />

      <NewPaymentModal
        getRootProps={
          payment?.paymentNumber === 1 ? getRootProps1 : getRootProps2
        }
        getInputProps={
          payment?.paymentNumber === 1 ? getInputProps1 : getInputProps2
        }
        selectPaymentMethod={selectPaymentMethod}
        paymentImage={
          payment?.paymentNumber === 1 ? paymentImage1 : paymentImage2
        }
        removeImage={removeImage}
        cancelPayment={cancelPayment}
        acceptPayment={acceptPayment}
        selectedMethod={selectedMethod}
        register={register}
        watch={watch}
        dollarRate={dollarRate}
        payment={payment}
        errors={errors}
        control={control}
        handleReferenceChange={handleReferenceChange}
        handleGenericPaymentMethods={handleGenericPaymentMethods}
      />
      <CasheaModal
        selectPaymentMethod={selectPaymentMethod}
        cancelPayment={cancelCasheaPayment}
        acceptPayment={acceptCasheaPayment}
        selectedMethod={selectedMethod}
        register={register}
        watch={watch}
        dollarRate={dollarRate}
        payment={casheaPayment}
        errors={errors}
        handleCasheaInitial={handleCasheaInitial}
        removeImage={removeImage}
        paymentImage={paymentImage1}
        getRootProps={getRootProps1}
        getInputProps={getInputProps1}
        options={{ paymemtMethodOptions: paymemtMethodOptions }}
      />
      <div className="landing-form-container">
        <h5>Información del Pago</h5>
        <p>(*) Campos obligatorios</p>
        <br />
        <div
        //  style={{ maxWidth: '280px' }}
        >
          <div className="row">
            <Input
              bootstrap={'col-12 col-md-6'}
              label={'Plan'}
              type={'text'}
              name={'selectedMembership'}
              register={register}
              placeholder={''}
              errors={errors?.selectedMembership?.message}
              disabled={true}
            />{' '}
            <Input
              bootstrap={'col-12 col-md-6'}
              label={'Precio USD'}
              type={'number'}
              name={'finalPrice'}
              register={register}
              placeholder={''}
              errors={errors?.finalPrice?.message}
              disabled={true}
            />{' '}
            <Input
              bootstrap={'col-12 col-md-6'}
              label={'Precio Bs'}
              type={'number'}
              name={'finalPriceInBsS'}
              register={register}
              placeholder={''}
              errors={errors?.finalPriceInBsS?.message}
              disabled={true}
            />{' '}
            {watch('finalPrice') !== 0 && (
              <>
                <Input
                  bootstrap={'col-12 col-md-6'}
                  label={'Tipo de pago *'}
                  type={'select'}
                  name={'paymentType'}
                  register={register}
                  options={paymentTypeVar}
                  placeholder={''}
                  errors={errors?.paymentType?.message}
                  onChange={setPaymentType}
                  disabled={watch('reference1')}
                />
                {![
                  paymentTypeOption?.COURTESY,
                  paymentTypeOption?.PENDING,
                ].includes(watch('paymentType')) && (
                    <Input
                      bootstrap={'col-12 col-md-6'}
                      label={'Cantidad de Métodos de pago *'}
                      type={'select'}
                      name={'paymentMethodsQuantity'}
                      register={register}
                      options={paymentMethodsLimit}
                      capitalizeOff={true}
                      placeholder={''}
                      errors={errors?.paymentMethodsQuantity?.message}
                      onChange={selectUniquePayment}
                      disabled={watch('reference1') || watch('paymentType') === paymentTypeOption?.CASHEA}
                    />
                  )}
              </>
            )}
          </div>
          <Method1Component
            watch={watch}
            register={register}
            errors={errors}
            getUSDFromBs={getUSDFromBs}
            paymentImage1={paymentImage1}
            validateReference={validateReference}
            validatePayment={validatePayment}
            paymemtMethodOptions={paymemtMethodOptions}
            setSecondPayment={setSecondPayment}
            selectPaymentMethod={selectPaymentMethod}
          />
          <Method2Component
            watch={watch}
            register={register}
            errors={errors}
            getUSDFromBs={getUSDFromBs}
            validateReference={validateReference}
            paymemtMethodOptions={paymemtMethodOptions}
            selectPaymentMethod={selectPaymentMethod}
            getBsfromUSD={getBsfromUSD}
            paymentImage2={paymentImage2}
            validatePayment={validatePayment}
          />
          <SecondQuotaComponent
            watch={watch}
            casheaPayment={casheaPayment}
            register={register}
            errors={errors}
            getUSDFromBs={getUSDFromBs}
            getBsfromUSD={getBsfromUSD}
            secondQuotaTime={secondQuotaTime}
            paymentImage1={paymentImage1}
            options={{ paymemtMethodOptions: paymemtMethodOptions }}
            validatePayment={validatePayment}
          />
        </div>
        <div className="buttons-center-container">
          {watch('paymentMethodsQuantity') && (
            <img className="safePayment" src={safePayment} alt="safePayment" />
          )}
        </div>
        <div
          style={{ margin: '24px 0px 48px 0px' }}
          className="buttons-center-container"
        >
          <AltButton
            label={'Regresar'}
            action={() => setcurentStep(curentStep - 1)}
          />

          {watch('finalPrice') !== 0 && validateButtonName()}
          {watch('finalPrice') === 0 && (
            <ActionButton
              label={'Continuar'}
              action={() => updateMembershipStatus(membership?._id)}
            />
          )}
        </div>
      </div>
    </>
  );
};
